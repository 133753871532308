import {
  Box,
  Flex,
  Stat,
  StatLabel, Text,
  StatNumber,
  useColorModeValue, SimpleGrid,
} from '@chakra-ui/react';
import moment from "moment";
function formatDate(year, month) {
  // Use current year and month if not provided
  if (!year || !month) {
    const currentDate = moment(); // Get current date
    year = currentDate.year();
    month = currentDate.month() + 1; // month() is 0-indexed, so add 1
  }

  return moment([year, month - 1]).format('MMM YYYY');
}
const MiniStatistics = (props) => {
  const { title,current,previous, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      //bg={'white'}
      border={'1px solid'}
      borderColor={'#63B3ED'}
      rounded={'lg'}
     // color={'#63B3ED'}
    >
      <Flex direction={'column'}>
        <Flex w={'full'}  gap={4} align={'center'}>
          <Flex
              my={'auto'}
              bg={'blue.100'}
              p={2}
              rounded={'md'}
              color={useColorModeValue('gray.800', 'gray.200')}
          >
            {icon}
          </Flex>
          <Box  w={'full'}>
            <StatLabel fontSize={'sm'} fontWeight={'medium'} isTruncated color='#2A4365'>
              {title}
            </StatLabel>
            <SimpleGrid
                columns={2}
                justifyContent={'space-between'}
            >
              <Box>
                <Text fontSize={'md'}  color={'gray.500'}>{formatDate(current?.year,current?.month)}</Text>
                <StatNumber fontSize={'2xl'}  color='#2A4365'>
                  {current?.count||0}
                </StatNumber>
              </Box>
              {previous&&previous?.month&&

              <Box>
                <Text fontSize={'md'}  color={'gray.500'}>{formatDate(previous?.year,previous?.month)}</Text>
                <StatNumber fontSize={'2xl'}  color='#2A4365'>
                  {previous?.count||0}
                </StatNumber>
              </Box>
              }

            </SimpleGrid>
          </Box>


        </Flex>

      </Flex>
      {/*  <Box pl={{ base: 2, md: 4 }}>

            <StatNumber fontSize={'2xl'} fontWeight={'medium'} color='#2A4365'>
              {stat}
            </StatNumber>
          </Box>
          <Box
              my={'auto'}
              color={useColorModeValue('gray.800', 'gray.200')}
              alignContent={'center'}
          >
            {icon}
          </Box>*/}
    </Stat>
  );
};

export default MiniStatistics;
