import React, {useState, useEffect} from 'react';
import Header from '../../components/Header/Header';
import MiniStatistics from '../../components/MiniStatistics/MiniStatistics';
import {Box, SimpleGrid, Flex} from '@chakra-ui/react';
import { BsSpeedometer } from 'react-icons/bs';
import {
  FiDollarSign,
  FiFileText,
} from 'react-icons/fi';
import {
  MdReport,
  MdPayment,
  MdWork,
  MdBuild,
  MdNotificationImportant,
  MdLogin,
  MdPeople,
} from 'react-icons/md';
import { GiWaterDrop, GiTap } from 'react-icons/gi';
import AnalyticsDashboard from '../../components/AnalyticsDashboard/AnalyticsDashboard';
import Footer from '../../components/Footer/Footer';
import { getSummationUserLogs, getCMSTallies,getLogsByDateRange } from './dashboardAPI';
import moment from 'moment';
import {Field} from "formik";
import {ThemedStyledInput} from "../../components/Styled";
const Dashboard = () => {
  const [logsByDate, setLogByDate] = useState();
  useEffect(() => {
    async function fetchLogsByTwoMonths() {
      const logs = await getLogsByDateRange();
      //console.log("Logs: ",logs)
      setLogByDate(logs);
    }
    fetchLogsByTwoMonths()
    },[])

  return (
    <div>
      <Header />
      <Flex
        bg='gray.50'
        minH={'100vh'}
        alignItems='center'
        justifyContent='center'
      >
        <Box
          maxW='8xl'
          mx={'auto'}
          px={{ base: '10', md: '0' }}
          pt={{ base: 10, sm: 0, md: 32 }}
        >
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }}>
            <MiniStatistics
              title={'Successful Customer Logins'}
              current={{
                count:logsByDate?.current?.totalSuccessfulLogins,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalSuccessfulLogins,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<MdLogin size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Successful Account Balance Checks'}
              current={{
                count:logsByDate?.current?.totalCheckBillCounts,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalCheckBillCounts,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<FiDollarSign size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Successful Meter Readings'}
              current={{
                count:logsByDate?.current?.totalMeterReadingCounts,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalMeterReadingCounts,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<BsSpeedometer size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Successful Bill Requests'}
              current={{
                count:logsByDate?.current?.totalCheckBillCounts,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalCheckBillCounts,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<FiFileText size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Registered Customers'}
              current={{
                count:logsByDate?.current?.totalRegisteredCustomers,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalRegisteredCustomers,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<MdPeople size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Online Payments'}
              current={{
                count:logsByDate?.current?.totalPaymentCount,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalPaymentCount,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<MdPayment size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Leak Reports'}
              current={{
                count:logsByDate?.current?.totalLeakReportCounts,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalLeakReportCounts,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<MdReport size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Water Shortage Reports'}
              current={{
                count:logsByDate?.current?.totalWaterShortageReportCounts,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalWaterShortageReportCounts,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<GiWaterDrop size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Connection Applications'}
              current={{
                count:logsByDate?.current?.totalConnectionRequests,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalConnectionRequests,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<GiTap size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Notices'}
              current={{
                count:logsByDate?.current?.totalNotices,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalNotices,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<MdNotificationImportant size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Prepaid'}
              current={{
                count:logsByDate?.current?.totalPrepaid,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalPrepaid,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<MdBuild size={'3em'} color='#63B3ED' />}
            />
            <MiniStatistics
              title={'Tenders'}
              current={{
                count:logsByDate?.current?.totalTenders,
                month:logsByDate?.current?.month,
                year:logsByDate?.current?.year,
              }}
              previous={{
                count:logsByDate?.previous?.totalTenders,
                month:logsByDate?.previous?.month,
                year:logsByDate?.previous?.year,
              }}
              icon={<MdWork size={'3em'} color='#63B3ED' />}
            />
          </SimpleGrid>
          <AnalyticsDashboard />
        </Box>
      </Flex>
      <Footer />
    </div>
  );
};

export default Dashboard;
