import { API1 } from "../../utils/api";
import utils from "../../utils/commonFunctions";

// #################################
//      USER STATS API FUNCTIONS
// #################################

export const getSummationUserLogs = async () => {
    try {
        const response = await API1.get('/logs-aggregator/logs/users/all/');
        return response.data;
    } catch (e) {
        utils.sendMessageToBugNinja("Failed to get summation of user logs", e.message, e)
        throw e;
    }
};
export const getLogsByDateRange = async () => {
    try {
        const response = await API1.get('/logs-aggregator/logs/statistics');
        //console.log("Logs: ",response)
        let data=response?.data
        let result={'current':data?.[0],'previous':data?.[1]}
        return result;
    } catch (e) {
        utils.sendMessageToBugNinja("Failed to get summation of user logs", e.message, e)
        console.error(e)
    }
};

export const getCMSTallies = async () => {
    try {
        const response = await API1.get('/cms/tallies');
        return response.data;
    } catch (e) {
        utils.sendMessageToBugNinja("Failed get cms tallies", e.message, e)
        throw e;
    }
};


export const getIndividualConnectionsStatistics = async () => {
    try {
        const response = await API1.get('/connection/summation-individual-applications');
        return response.data;
    } catch (e) {
        utils.sendMessageToBugNinja("Failed get individual connection request stats", e.message, e)
        throw e;
    }
};

export const getWaterShortageReportStatistics = async () => {
    try {
        const response = await API1.get('/shortages/statistics');
        return response.data;
    } catch (e) {
        utils.sendMessageToBugNinja("Failed to get water shortage stats", e.message, e)
        throw e;
    }
};

export const getPaymentStatistics = async () => {
    try {
        const response = await API1.get('/payment/get/statistics');
        return response.data;
    } catch (e) {
        utils.sendMessageToBugNinja("Failed to get payment stats", e.message, e)
        throw e;
    }
};